import React from 'react'
import Swiper from 'react-id-swiper'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import HomepageSectionWrapper from '~/components/Festival/common/HomepageSectionWrapper'

const ANIMATION_DURATION = 400
const INTERVAL_DURATION = 20000
const COUNTDOWN_DURATION = INTERVAL_DURATION - ANIMATION_DURATION

@withNamespaces('homepage-bw')
export default class ReviewsSection extends React.Component {

  state = {
    reviewPage: 0,
    isAnimated: false,
    isTransitionDone: true
  }

  componentDidMount () {
    this.initSwiper()
    // this.setSlidesInterval()
    // this.updateSwiper()
  }

  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
    clearInterval(this.slidesInterval)
  }

  get reviews () {
    return [
      'review_1',
      'review_2',
      'review_3',
      'review_4',
      'review_5',
    ]
  }

  initSwiper = () => {
    this.swiper && this.swiper.init()
  }

  updateSwiper = () => {
    this.swiper && this.swiper.update()
  }

  setSlidesInterval = () => {
    this.slidesInterval = setInterval(() => {
      this.updateSwiper()
      this.swiper && this.swiper.slideNext()
    }, INTERVAL_DURATION)
  }

  goToPrev = () => {
    this.swiper && this.swiper.slidePrev()
    clearInterval(this.slidesInterval)
    this.setSlidesInterval()
  }

  goToNext = () => {
    this.swiper && this.swiper.slideNext()
    clearInterval(this.slidesInterval)
    this.setSlidesInterval()
  }

  changeReviewPage = (index) => this.setState({ reviewPage: index })

  setTransitionCompleteFlag = (flag) => this.setState({ isTransitionDone: flag })

  stopIndicatorAnimation = () => this.setState({ isAnimated: false })

  startIndicatorAnimation = () => this.setState({ isAnimated: true })

  render () {
    const { reviewPage, isAnimated, isTransitionDone } = this.state
    const { t } = this.props

    const swiperParams = {
      init: false,
      effect: 'fade',
      speed: ANIMATION_DURATION,
      loop: true,
      autoHeight: false,
      observer: true,
      slideActiveClass: 'review-card--active',
      slideDuplicateActiveClass: 'review-card--active',
      breakpoints: {
        767: {
          autoHeight: true,
        },
      },
      on: {
        init: () => {
          this.startIndicatorAnimation()
        },
        slideChange: () => {
          this.updateSwiper()
          this.swiper && this.changeReviewPage(this.swiper.realIndex)
        },
        slideChangeTransitionStart: () => {
          this.stopIndicatorAnimation()
          this.setTransitionCompleteFlag(false)
        },
        slideChangeTransitionEnd: () => {
          this.startIndicatorAnimation()
          this.setTransitionCompleteFlag(true)
        },
        touchStart: () => {
          clearInterval(this.slidesInterval)
        },
        touchEnd: () => {
          this.setSlidesInterval()
        },
        resize: () => {
          this.updateSwiper()
        },
        observerUpdate: () => {
          this.updateSwiper()
        }

      }
    }

    return (
      <section className='reviews-section'>
        <HomepageSectionWrapper
          title={t('reviews_section.title')}
          description={t('reviews_section.description')}
          sectionId='reviews-section'
          modifiers={'reviews'}
        >
          <div className={bemCx('reviews-section__swiper', { 'transition-done': isTransitionDone })}>
            <Swiper {...swiperParams} ref={node => { if (node) { this.swiper = node.swiper } }}>
              {this.reviews.map((item, index) => (
                <div key={item} className='review-card'>
                  <div className='review-card__content' >
                    <div className='review-card__stars i-stars' />
                    <p className='review-card__description'>{t(`reviews_section.reviews.${item}.description`)}</p>
                    <div className='review-card__user'>
                      {/* <div className='review-card__user-img' /> */}
                      <p className='review-card__user-name'>{t(`reviews_section.reviews.${item}.author`)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>
            <div className={bemCx('review-card', ['in-background', 'first'])} />
            <div className={bemCx('review-card', ['in-background', 'second'])} />
            <div className={bemCx('reviews-section__nav-btn', 'prev')} onClick={this.goToPrev}>
              <div className='reviews-section__nav-arrow i-review-arrow-prev' />
            </div>
            <div className={bemCx('reviews-section__nav-btn', 'next')} onClick={this.goToNext}>
              <div className='reviews-section__nav-arrow i-review-arrow-next' />
              <svg className={bemCx('reviews-section__countdown-indicator', { 'is-animated': isAnimated })}>
                <circle r='23' cx='24' cy='24' style={{ animationDuration: `${COUNTDOWN_DURATION}ms` }} />
              </svg>
            </div>
          </div>
          <div className='reviews-section__pagination'>
            {this.reviews.map((item, index) => (
              <span
                key={item}
                className={bemCx('reviews-section__pagination-item', { 'active': index === reviewPage })}
              />
            ))}
          </div>
        </HomepageSectionWrapper>
      </section>
    )
  }
}
