import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import WeOfferSection from '~/components/Festival/common/WeOfferSection'
import ExplanationSection from '~/components/Festival/common/ExplanationSection'
// import HeroSection from '~/components/Festival/common/HeroSection/HeroSectionGql'
// import HeroBanner from '~/components/HeroBanner'
import TakePartSection from '~/components/Festival/common/TakePartSection/TakePartSectionGql'
// import FestivalMissionSection from '~/components/Festival/common/FestivalMissionSection'
import InstagramSection from '~/components/Festival/common/InstagramSection'
import VoucherSection from '~/components/Festival/common/VoucherSection'
import ReviewsSection from '~/components/Festival/bw/ReviewsSection'
import LogosSection from '~/components/LogosSection/LogosSectionGql'
import HeroWrapper from './HeroWrapper'

const OFFERS = {
  offer_1: 'book',
  offer_2: 'percent',
  offer_3: 'coffee',
  offer_4: 'present',
  offer_5: 'guest',
  offer_6: 'time'
}

export default class Index extends React.Component {
  render () {
    const { isVoucher } = this.props

    return <div>
      <div className='container'>
        <HeroWrapper />
      </div>
      {isVoucher &&
        <div className='container'>
          <VoucherSection />
        </div>
      }
      <div className='container'>
        <ExplanationSection />
      </div>
      {/* <FestivalMissionSection
        paragraphs={['paragraph_1', 'paragraph_2', 'paragraph_3']}
      /> */}
      <div className='container'>
        <WeOfferSection offers={OFFERS} />
      </div>
      <ReviewsSection />
      <InstagramSection />
      <div className='container'>
        <LogosSection loadImmediately />
      </div>
      {!isVoucher && <TakePartSection modifiers='index' withRespect={false} />}
      <Footer />
    </div>
  }
}
