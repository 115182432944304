import React from 'react'
import HeroSection from '~/components/Festival/bw/HeroSection/HeroSectionGql'

export default class HeroWrapper extends React.Component {
  render () {
    return (
      <HeroSection
        slides={[
          { src: 'slider-bw-1.png' },
          { src: 'slider-bw-2.png' },
          { src: 'slider-bw-3.png' },
          { src: 'slider-bw-4.png' },
        ]}
        heroWithTag
      />
    )
  }
}
